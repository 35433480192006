import React from "react"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Title from "../../components/title"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "../../components/button"
import * as styles from "./about.module.scss"

export default function AboutPage({ data }) {
  return (
    <>
      <Seo title="About" />
      <Layout mobileStickyScrollPosition={-150}>
        <section className={styles.about}>
          <header>
            <Title headingLevel="h4">About us</Title>
            <Title headingLevel="h1">We are Grycode</Title>
            <Title headingLevel="p">
              We are a front-end development company that offers interactive
              front-end development services to companies of all sizes in order
              to construct bespoke, scalable, and secure apps. We use
              cutting-edge tools and frameworks such as React, Angular, Vue JS,
              Svelte, Ember JS, and others to design and construct strong,
              user-friendly interfaces.
            </Title>

            <Title headingLevel="p">
              We use agile front-end development methodology, programming
              methods, coding standards, and include UI/UX design into every
              step of the app development life-cycle, ensuring that end-user
              needs are prioritised throughout the application development
              process. Our trained front-end developers are professionals at
              developing feature-rich applications for a wide range of users.
            </Title>
          </header>
          <GatsbyImage image={data.aboutHero.sharp.gatsbyImageData} alt="" />
        </section>

        <section className={styles.values}>
          <header>
            <Title headingLevel="h4">Things we value</Title>
            <Title headingLevel="h2">Believability at the core</Title>
            <Title headingLevel="p"></Title>
          </header>
          <div className={styles.content}>
            <div>
              <GatsbyImage
                image={data.transparency.sharp.gatsbyImageData}
                alt=""
                style={{ width: 200 }}
              />
              <Title headingLevel="h3">Transparency and Truthfulness</Title>
              <p>
                We communicate with each other honestly, even if the truth
                violates one's ego. We believe that in the long term only
                through good and honest communication with each other we can
                generate added value for our clients.
              </p>
            </div>

            <div>
              <GatsbyImage
                image={data.mind.sharp.gatsbyImageData}
                alt=""
                style={{ width: 200 }}
              />
              <Title headingLevel="h3">Openness of mind</Title>
              <p>
                In our case transparency means that everyone's opinion is open
                for discussion by anyone on the team. That is why it is so
                important for us to keep our mind open and treat every feedback
                with humility, a chance to look at things from another
                perspective, and as an opportunity to improve our skills.
              </p>
            </div>
          </div>
          <footer>
            <div>
              <Title headingLevel="p">
                For us customers really do come first because we help them not
                only grow their businesses but also educate them to understand
                the tech behind it.
              </Title>
              <span>
                <Button to="/start-project">Get in touch</Button>
              </span>
            </div>
          </footer>
        </section>
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    aboutHero: file(relativePath: { eq: "about-hero.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    transparency: file(relativePath: { eq: "transparency.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    mind: file(relativePath: { eq: "mind.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    commit: file(relativePath: { eq: "commit.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    confirm: file(relativePath: { eq: "confirm.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
